<ng-container *ngIf="auth.isLoading$ | async; else notLoading">
    <div class="spinner-border" role="status"></div>
</ng-container>
<ng-template #notLoading>
    <ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
        <div class="btn-group">
            <button type="button" class="btn btn-image dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <svg-icon name="user" svgClass="user-icon" [stretch]="true"></svg-icon>
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
                <li>
                    <ng-container *ngIf="isLoadingUserInformation$ | async; else userInformationLoaded">
                        <div class="w-100 d-flex justify-content-center">
                            <div class="spinner-border" role="status"></div>
                        </div>
                    </ng-container>
                    <ng-template #userInformationLoaded>
                        <span class="px-2">{{fullName}}</span>
                        <div class="px-2">
                            <ul class="role" *ngIf="isSuperAdministrator">
                                <li>Super Administrator</li>
                            </ul>
                            <ul class="role" *ngFor="let customerGroupRole of associatedCustomerGroupRoles">
                                <li>
                                    <div>{{customerGroupRole.customerGroupName}}</div>
                                    <div>{{customerGroupRole.roleName}}</div>
                                </li>
                            </ul>
                            <ul class="role" *ngFor="let customerRole of associatedCustomerRoles">
                                <li>
                                    <div>{{customerRole.customerName}}</div>
                                    <div>{{customerRole.roleName}}</div>
                                </li>
                            </ul>
                        </div>
                    </ng-template>
                </li>
                <li>
                    <hr class="dropdown-divider">
                </li>
                <li><button class="dropdown-item" type="button"
                        (click)="auth.logout({ logoutParams: { returnTo: document.location.origin } })">Logout</button>
                </li>
            </ul>
        </div>
    </ng-container>
    <ng-template #loggedOut>
        <button class="btn btn-dark-link" (click)="auth.loginWithRedirect()">Login</button>
    </ng-template>
</ng-template>