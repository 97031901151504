import { CommonModule, DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { SubSink } from 'subsink';
import { UserService } from '../../../admin/services/user.service';
import { User } from '../../../admin/models/user';
import { BehaviorSubject } from 'rxjs';
import { Role, Roles } from '../../../admin/constants/role';
import { CustomerGroupUserRole } from '../../../admin/models/customer-group-user-role';
import { CustomerUserRole } from '../../../admin/models/customer-user-role';

@Component({
  selector: 'app-login-button',
  templateUrl: './login-button.component.html',
  styleUrl: './login-button.component.scss'
})
export class LoginButtonComponent implements OnInit, OnDestroy {
  user?: User;

  private sub = new SubSink();
  isLoadingUserInformation$ = new BehaviorSubject<boolean>(false);
  
  constructor(@Inject(DOCUMENT) public document: Document, public auth: AuthService, private userService: UserService) {
  }

  get fullName(): string {
    return !!this.user ? `${this.user.firstName} ${this.user.lastName}` : '';
  }

  get isSuperAdministrator(): boolean {
    return this.user?.customerUserRoles?.some(x => x.roleName == Role.SuperAdministrator) ?? false;
  }

  get associatedCustomerRoles(): CustomerUserRole[] {
    return this.user?.customerUserRoles?.filter(x => x.roleName != Role.SuperAdministrator) ?? [];
  }

  get associatedCustomerGroupRoles(): CustomerGroupUserRole[] {
    return this.user?.customerGroupUserRoles ?? [];
  }

  ngOnInit(): void {
    this.isLoadingUserInformation$.next(true);
    this.sub.sink = this.userService.getCurrentUser().subscribe((user) => {
      this.user = user;
      this.isLoadingUserInformation$.next(false);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
