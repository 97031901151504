<div class="content-width">
  <nav class="navbar navbar-expand-lg">
    <a class="navbar-brand" [href]="trinityRailMarketUrl">
      <svg width="160" height="38" viewBox="0 0 160 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_196_8121)">
          <path d="M135.875 11.5498V12.2878H159.262V36.9957H46.1022V17.5497H45.3643V37.7337H160V11.5498H135.875Z"
            fill="black" />
          <path
            d="M87.8911 2.72363L85.6107 6.23647L83.3266 2.72363H70.8398V5.21435H74.611V14.1403H77.681V5.21066H81.4005L84.2381 9.59063V14.1403H86.9797V9.59063L91.4261 2.72363H87.8911Z"
            fill="black" />
          <path
            d="M114.798 2.71973C115.813 2.71973 116.813 2.71973 117.824 2.71973C117.824 6.52776 117.824 10.3321 117.824 14.1438C116.813 14.1438 115.809 14.1438 114.798 14.1438C114.798 10.3358 114.798 6.53145 114.798 2.71973Z"
            fill="black" />
          <path
            d="M44.0727 9.69764C45.379 8.98917 46.0764 7.74566 46.0764 6.41727C46.0764 4.37673 44.4233 2.72363 42.3827 2.72363H24.6636V5.20328H28.4421C28.4421 5.20328 28.4421 11.1995 28.4421 14.144C29.4716 14.144 30.4789 14.144 31.5121 14.144C31.5121 11.1921 31.5121 5.19959 31.5121 5.19959H36.3644C36.3644 7.76411 36.3644 11.7234 36.3644 14.144H39.1024L39.1097 5.20328H42.0174C42.689 5.20328 43.2314 5.7457 43.2314 6.41727C43.2314 7.08885 42.689 7.63127 42.0174 7.63127C42.0137 7.63127 40.63 7.63127 39.8182 7.63127L44.1613 14.144H47.4269L44.0727 9.69764Z"
            fill="black" />
          <path d="M123.182 11.5645V2.71973H120.148V14.1438H128.174L129.613 11.5645H123.182Z" fill="black" />
          <path
            d="M131.259 11.5645C131.963 11.5645 132.521 12.1364 132.521 12.8522C132.521 13.5792 131.96 14.1437 131.251 14.1437C130.547 14.1437 129.975 13.5792 129.975 12.8522C129.975 12.1364 130.547 11.5645 131.251 11.5645H131.259ZM131.255 11.7637C130.687 11.7637 130.226 12.2508 130.226 12.8486C130.226 13.4611 130.69 13.9408 131.262 13.9408C131.834 13.9482 132.292 13.4611 132.292 12.8559C132.292 12.2508 131.834 11.7637 131.262 11.7637H131.255ZM131.015 13.6013H130.786V12.1659C130.908 12.1475 131.023 12.1327 131.192 12.1327C131.41 12.1327 131.554 12.177 131.639 12.2397C131.724 12.3024 131.772 12.3984 131.772 12.5386C131.772 12.7268 131.646 12.8412 131.491 12.8891V12.9002C131.617 12.9224 131.702 13.0367 131.731 13.2508C131.764 13.4722 131.797 13.5607 131.823 13.605H131.583C131.55 13.557 131.513 13.4279 131.487 13.2397C131.454 13.0552 131.362 12.9888 131.178 12.9888H131.019V13.6013H131.015ZM131.015 12.8043H131.181C131.369 12.8043 131.528 12.7342 131.528 12.557C131.528 12.4316 131.436 12.3061 131.181 12.3061C131.107 12.3061 131.056 12.3135 131.015 12.3172V12.8043Z"
            fill="black" />
          <path d="M69.4672 2.72363H66.7256V14.1477H69.4672V2.72363Z" fill="black" />
          <path
            d="M61.6886 2.72363V9.38769L56.2976 2.73101H53.7441V14.1477H56.4858V7.53164L61.8399 14.1477H64.4302V2.72363H61.6886Z"
            fill="black" />
          <path d="M51.2753 2.72363H48.5337V14.1477H51.2753V2.72363Z" fill="black" />
          <path
            d="M108.846 2.71973L108.82 2.72711V2.72342L106.333 2.71973L101.614 12.9667L99.149 9.69743C100.455 8.98896 101.153 7.74544 101.153 6.41706C101.153 4.37651 99.4995 2.72342 97.459 2.72342L91.4443 2.71973V14.1438H94.1823L94.1897 5.20306H97.0973C97.7689 5.20306 98.3113 5.74549 98.3113 6.41706C98.3113 7.08863 97.7689 7.63105 97.0973 7.63105C97.0937 7.63105 94.8981 7.63105 94.8981 7.63105L99.2412 14.1438L104.234 14.1475L105.123 11.9667H110.09L110.986 14.1475L114.119 14.1438L108.846 2.71973ZM106.038 9.7085L107.588 5.90416L109.149 9.7085H106.038Z"
            fill="black" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.269 9.16211H20.0032L11.269 17.0365V9.16211Z"
            fill="black" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9.16211H8.73412V17.0365L0 9.16211Z" fill="black" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99977 0L19.9848 6.54597H0L9.99977 0Z" fill="black" />
          <path
            d="M63.6258 28.8111H61.9948L63.2715 21.5862L59.7845 28.8111H57.9396L57.054 21.6895L55.8031 28.8111H54.3013L55.7773 20.4238H58.4562L59.2274 26.789L62.2199 20.4238H65.0981L63.6258 28.8111Z"
            fill="black" />
          <path
            d="M70.0425 20.4238H71.8616L73.5922 28.8111H71.8875L71.5849 27.1432H67.9355L67.013 28.8111H65.2197L70.0425 20.4238ZM71.345 25.8554L70.6624 22.1802L68.6403 25.8554H71.345Z"
            fill="black" />
          <path
            d="M80.7656 25.4385L82.4962 28.8111H80.6254L79.1346 25.6414H77.5701L77.0129 28.8111H75.334L76.81 20.4238H80.2305C81.8467 20.4238 83.0091 21.3094 83.0091 22.7596C83.0128 24.0252 82.1161 25.0584 80.7656 25.4385ZM77.7989 24.3278H79.4409C80.6401 24.3278 81.3117 23.7226 81.3117 22.8998C81.3117 22.1655 80.7693 21.7633 79.9243 21.7633H78.2564L77.7989 24.3278Z"
            fill="black" />
          <path
            d="M85.7952 20.4238H87.463L85.987 28.8111H84.3081L85.7952 20.4238ZM90.6954 20.4238H92.7286L88.6881 24.4385L91.3522 28.8074H89.4704L86.832 24.3758L90.6954 20.4238Z"
            fill="black" />
          <path
            d="M94.6989 20.4238H100.559L100.621 21.7633H96.138L95.7727 23.8702H99.042L98.8132 25.1839H95.5439L95.138 27.4716H99.673L99.2929 28.8111H93.2192L94.6989 20.4238Z"
            fill="black" />
          <path
            d="M102.795 20.4238H109.26L109.322 21.7633H106.71L105.474 28.8111H103.795L105.031 21.7633H102.418L102.795 20.4238Z"
            fill="black" />
          <path
            d="M111.448 20.4238H114.732C116.348 20.4238 117.51 21.3316 117.51 22.9625C117.51 24.7189 116.16 26.0436 113.887 26.0436H112.13L111.651 28.8111H109.972L111.448 20.4238ZM113.794 24.7079C114.983 24.7079 115.802 24.088 115.802 23.0031C115.802 22.2467 115.396 21.767 114.4 21.767H112.883L112.366 24.7079H113.794Z"
            fill="black" />
          <path d="M124.167 27.4716L123.787 28.8111H118.281L119.757 20.4238H121.436L120.2 27.4716H124.167Z"
            fill="black" />
          <path
            d="M130.289 20.4238H132.108L133.838 28.8111H132.134L131.831 27.1432H128.182L127.259 28.8111H125.466L130.289 20.4238ZM131.587 25.8554L130.905 22.1802L128.883 25.8554H131.587Z"
            fill="black" />
          <path
            d="M142.462 20.642L142.524 22.1697C141.816 21.8412 141.06 21.5645 140.277 21.5645C138.207 21.5645 136.982 23.4722 136.982 25.4685C136.982 27.0478 137.739 27.6898 139.067 27.6898C139.875 27.6898 140.746 27.45 141.595 27.1068L141.189 28.5459C140.521 28.8485 139.646 29.0514 138.775 29.0514C136.665 29.0514 135.277 27.8005 135.277 25.4537C135.277 22.701 137.185 20.1992 140.251 20.1992C141.111 20.1992 141.831 20.3874 142.462 20.642Z"
            fill="black" />
          <path
            d="M144.864 20.4238H150.724L150.786 21.7633H146.303L145.938 23.8702H149.207L148.978 25.1839H145.709L145.303 27.4716H149.838L149.458 28.8111H143.384L144.864 20.4238Z"
            fill="black" />
        </g>
        <defs>
          <clipPath id="clip0_196_8121">
            <rect width="160" height="37.7335" fill="white" />
          </clipPath>
        </defs>
      </svg>

    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" routerLink="/dashboard" role="button" data-bs-toggle="dropdown"
            aria-expanded="false" (click)="onClick(false)">
            Rail Network Performance
          </a>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item" (click)="onClick(false)" routerLink="/dashboard/railcar-volume">
                Railcar Volume
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="onClick(false)" routerLink="/dashboard/network-dwell">
                Network Dwell
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="onClick(false)" routerLink="/dashboard/cycle-time">
                Cycle Time
              </a>
            </li>
            <li *ngIf="userService.isUserInGroup('WCSC') | async">
              <a class="dropdown-item" routerLink="/dashboard/railstate-compare-contrast">
                Railstate Comparison
              </a>
            </li>
          </ul>
        </li>
        <div class="nav-item dropdown" *ngIf="showForecastDemand">
          <button class="forecast-demand" [ngClass]="showActive ?'forecast-demand-active':'' " (click)="onClick(true)"
            routerLink="/forecast-dashboard" role="button" aria-expanded="false">
            Forecast &amp; Demand
          </button>

        </div>
        <li class="nav-item dropdown" *ngIf="userService.isUserInRole(Role.SuperAdministrator) | async">
          <a class="nav-link dropdown-toggle" (click)="onClick(false)" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Admin
          </a>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item" (click)="onClick(false)" routerLink="/admin/customer/management">
                Customer Management
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="onClick(false)" routerLink="/admin/user/management">
                User Management
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <app-login-button></app-login-button>
        </li>
      </ul>
    </div>
  </nav>
</div>