import { Component, NgModule } from '@angular/core';
import { EnvironmentInformationDataService } from '../../modules/shared-rail-performance/services/environment-information-data.service';
import { EnvironmentInformation } from '../../modules/shared-rail-performance/models/environment-information';
import { QueryObserverResult } from '@tanstack/query-core';
import { Observable } from 'rxjs';
import { version } from './../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  // Variable to toggle cookie pop-up
  showCookies = true;

  // Cookie dialog is closing
  exitCookies = false;

  // add other consent cookie info once the information is available
  CONSENT_COOKIE = {
    consent: false,
  };

  constructor() { }

  ngOnInit(): void {
    this.showCookies = this.getCookie('consent') === '';
  }

  get versionNumber(): string {
    return version;
  }

  get currentYear(): string {
    return new Date().getFullYear().toString();
  }

  /**
   * Closes the cookie dialog
   * @param acceptsCookies user's consent for cookies
   */
  closeCookieDialog(acceptsCookies: boolean) {
    this.exitCookies = true;

    this.CONSENT_COOKIE.consent = acceptsCookies;
    this.setCookie('consent', JSON.stringify(this.CONSENT_COOKIE), 365);
    setTimeout(() => {
      this.showCookies = false;
    }, 600);
  }

  showCookieDialog() {
    this.showCookies = true;
    this.exitCookies = false;
  }

  /**
   * Creates a cookie
   * @param cname name of the cookie
   * @param cvalue value of the cookie. If it is an object it must be converted to string
   * @param exdays days till cookie expires
   */
  setCookie(cname: string, cvalue: string, exdays: number) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  /**
   * Searches for a cookie and returns its value
   * @param cname name of cookie to search
   */
  getCookie(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  setMixpanelTrack() {
    const userEmail = localStorage.getItem('userEmail') || '';
  //  this.mixpanelService.track({
  //    id: userEmail,
  //    eventName: 'contact_us',
  //    eventDisplayName: 'Quick Links Contact Us',
  //    kpi: 'CTR',
  //    trigger: 'Link Click',
  //    eventDefinition: 'User clicks the contact us breadcrumb',
  //    propertyType: 'Event Property',
  //    propertyDefinition: 'Breadcrumb Label',
  //  });
  }

  get copyrightYear(): number {
    return new Date().getFullYear();
  }

}
